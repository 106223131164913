.text-editors {
    .editor-tabs {
        margin: 50px auto;
    }

    .save-button {
        margin: 15px auto;
    }

    .preview {
        margin: 50px auto;
    }

    @media screen and (max-width: 768px) {
        .w-md-editor-toolbar {
            height: 90px !important;
        }
    }
}
