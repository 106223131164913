.sidebar-filter {
    @media screen and (min-width: 2000px) {
        left: 0;
        top: 0;
        height: 100%;
        max-width: 500px;
    }
}

.filter-container {
    padding: 50px 45px 45px 40px;
    box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.1);
    height: 100%;

    .close-menu {
        display: none;
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        height: 100vh;
        background-color: $secondary;
        z-index: 1000;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 85%;
        max-width: 320px;
        transform: translateX(-100%);
        transition: $transition;
        padding: 0;

        &.show {
            transform: translateX(0);
            transition: $transition;
        }

        .filter-box{
            padding: 0 30px;
        }

        .close-menu {
            font-size: 22px;
            position: sticky;
            right: 10px;
            top: 5px;
            display: block;
            padding-bottom: 20px;
            margin-right: 10px;
            text-align: right;
        }
    }

    select {
        margin: 0 10px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.329);
    }

    .equipments {
        position: relative;

        .equ-selector {
            position: absolute;
            left: 0px;
            top: 30px;
            width: 100%;
            background-color: white;
            font-size: 15px;
            z-index: 10;
            text-align: left;
            border-radius: 5px;
            box-shadow: 0 2px 2px 2px rgba(128, 128, 128, 0.479);
            padding: 5px;

            &:empty {
                display: none;
            }

            .equ-select {
                padding: 3px 0;
                cursor: pointer;

                &:hover {
                    background-color: $lightBlue;
                }
            }
        }

        .equ-list-item {
            margin-top: 10px;
            background-color: $lightBlue;
            padding: 4px 8px;
            border-radius: 5px;
        }

        .equ-list-item-icon {
            cursor: pointer;
            padding: 2px;
            width: 17px;
            height: 17px;
            border: 1px solid $lightBlue;
            border-radius: 3px;
            background-color: #fff;

            &:hover {
                color: $warning;
            }
        }
    }

    .filter-box {
        max-width: 300px;
        margin: auto;
        margin-bottom: 35px;

        .filter-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;

            &.counter-title {
                margin-right: 26px;
            }
        }

        .checkbox-square {
            width: 30px;
            height: 30px;
            flex-basis: 30px;
            flex-grow: 0;
            flex-shrink: 0;
            background-color: #eaedee;
            border-radius: 10px;
            margin-bottom: 0;
            margin-right: 14px;
            cursor: pointer;

            + label {
                cursor: pointer;
            }
        }

        input[type="checkbox"]:checked + .checkbox-square {
            background-color: $gold;

            &::after {
                content: "\f00c";
                font-family: "FontAwesome";
                color: $secondary;
            }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }

        .counter input {
            width: 30px;
            text-align: center;

            &:focus-visible {
                outline: none;
            }
        }

        input[type="text"] {
            width: 100%;
            max-width: 150px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $lightBlue;
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 65px;
            height: 32px;
            margin-left: 22px;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid $lightBlue;
            @include transition;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 4px;
            bottom: 2px;
            background-color: $lightBlue;
            @include transition;
        }

        input:checked + .slider {
            border-color: $gold;

            &::before {
                transform: translateX(30px);
                background-color: $gold;
            }
        }

        .slider.round {
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
}
