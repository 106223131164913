.last-minute-banner {
    max-width: 1160px;
    margin: auto;
    padding: 40px 20px;
    min-height: 500px;

    @include media-breakpoint-up(xl) {
        padding: 80px 0;
    }

    .row {
        padding-bottom: 20px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 80px;
        }
    }

    .main-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
            margin-bottom: 60px;
            line-height: 52px;
        }
    }

    .last-minute-ship-box {
        box-shadow: 0px 0px 40px rgba(13, 28, 61, 0.15);
        border-radius: 10px;
        max-width: 359px;
        margin: auto;

        .image-container {
            position: relative;

            img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 100%;
                height: 240px;
                object-fit: cover;
            }
        }

        .box-content {
            padding: 22px 25px 27px 25px;

            p {
                color: $base;
            }

            svg {
                color: $lightBlue;
                margin-right: 10px;
            }

            .ship-title {
                font-size: 22px;
                font-weight: 600;
                line-height: 40px;
            }

            .ship-year {
                line-height: 21px;
                margin-bottom: 15px;
            }

            .location {
                margin-bottom: 27px;

                svg {
                    font-size: 23px;
                }

                p {
                    line-height: 21px;
                }
            }

            .information-item {
                margin-bottom: 24px;

                p,
                span {
                    font-size: 14px;
                }
            }

            .info-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 15px;
            }

            .info {
                width: 33.3%;
            }

            .bed svg {
                font-size: 20px;
            }

            .length svg,
            .height svg {
                font-size: 23px;
            }

            .availability {
                margin-bottom: 32px;

                svg {
                    font-size: 22px;
                }
            }

            .price-box {
                margin-bottom: 25px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 39px;
                }

                .current-price {
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 40px;
                    margin-right: 16px;

                    @include media-breakpoint-up(lg) {
                        font-size: 40px;
                    }
                }

                .old-price {
                    font-size: 14px;
                    text-decoration: line-through;
                }
            }

            .view-btn {
                font-weight: 600;
                line-height: 40px;
                @include transition;

                &:hover {
                    color: $base !important;
                    text-decoration: none;
                    @include transition;

                    svg {
                        color: $base !important;
                        @include transition;
                    }
                }

                svg {
                    margin-left: 8px;
                    @include transition;
                }
            }
        }
    }

    .more-btn {
        border: 2px solid $gold;
        padding: 6px 12px;
        display: block;
        max-width: 200px;
        margin: auto;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        @include transition;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            padding: 6px 24px;
            max-width: 239px;
        }

        &:hover {
            background-color: $gold !important;
            color: $secondary !important;
            text-decoration: none;
            @include transition;
        }
    }
}
