@import "components/variables";
@import "~bootstrap/scss/bootstrap";
@import "components/mixins";
@import "components/header";
@import "components/footer";
@import "components/searchbar";
@import "components/lastminute";
@import "components/specialbanner";
@import "components/howitworkbanner";
@import "components/newsletter";
@import "components/searchresult";
@import "components/registration";
@import "components/searchfilter";
@import "components/social";
@import "components/offerdetails";
@import "components/relatedoffers";
@import "components/profile";
@import "components/adminlogin";
@import "components/adminsidebar";
@import "components/adminnavbar";
@import "components/admininfos";
@import "components/date-picker";
@import "components/reservations";
@import "components/reservationdetails";
@import "components/carosuel";
@import "components/edituserdata.scss";
@import "components/admin-table";
@import "components/payment-popup";
@import "components/reservationmodal";
@import "components/userreservationdetails.scss";
@import "components/loader.scss";
@import "components/text-editors.scss";

//screens
@import 'screens/search';
@import 'screens/profileScreen';
@import 'screens/howitworksscreen';
@import 'screens/contacts';
@import 'screens/privacy-statemant';
@import 'screens/terms-of-use';
@import 'screens/admin-login';
@import 'screens/password-reset';

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

body.blur {
    &::after {
        content: "";
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
}

p {
    margin-bottom: 0;
}

//global loader
.lds-dual-ring {
    width: 100%;
    height: 80px;
    margin-left: 45%;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: $primary transparent $primary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// for markdown displayer, at titles --> hide the icon of the links
.octicon-link{
    display: none;
}
