.related-offers {
    padding: 40px 20px 30px 20px;

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .main-title {
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 40px;

            @include media-breakpoint-up(lg) {
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 50px;
            }
        }

        .ship-box {
            width: 100%;
            box-shadow: 0px 0px 33.8457px rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            .image-container {
                position: relative;

                img {
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 200px;
                    object-fit: cover;
                }

                .discount {
                    position: absolute;
                    right: -10px;
                    bottom: 18px;
                    border-radius: 8.3px;
                    padding: 5px 20px 5px 10px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                }

                .rating {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    font-size: 0;
                    background-color: rgba(255, 255, 255, 0.6);
                    padding: 5px 6px;
                    border-radius: 140px;
                }
            }

            .box-content {
                padding: 13px 22px;

                .title {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 40px;
                    margin-bottom: 8px;
                }

                p.year,
                p.availability {
                    font-size: 14px;
                    line-height: 130%;
                }

                p.price {
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 33px;
                    margin-top: 18px;
                    display: inline-block;
                    border-radius: 10px;
                    padding: 5px 13.5px;
                    background: url(../../assets/images/price-background.png);
                    background-size: 100% 100%;
                    margin-bottom: 15px;
                }

                .view-btn {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 33px;
                    @include transition;

                    &:hover {
                        color: $base !important;
                        @include transition;
                    }

                    svg {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}
