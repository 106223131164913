.adminnavbar {
  border-bottom: 1px solid $base;
  margin-bottom: 30px;
  padding: 10px 0;

  button:hover{
    background-color: $gold !important;
    border-color: $gold;
  }
}
